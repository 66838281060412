import React from 'react';
import styles from './Home.module.css';
import ActionableInsights from '../../assets/data-photo.png';
import BoostEngagement from '../../assets/engagement.png';
import CostEffective from '../../assets/cost-effective.png';
import Divider from '../../elements/Divider/divider';
import EnhanceProductivity from '../../assets/productivity.png';
import ForbesLogo from '../../assets/forbes-logo.png';
import Heart1 from '../../assets/heart-1.svg';
import Heart2 from '../../assets/heart-2.svg';
import HeroPhoto from '../../assets/hero-photo.png';
import Pill1 from '../../assets/pill-1.svg';
import Pill2 from '../../assets/pill-2.svg';
import ReduceTurnover from '../../assets/turnover.png';
// import VideoPlaceholder from '../../assets/video-placeholder.png';

const Home = () => {

  // Parallax effect on the icons in the background
  window.addEventListener('scroll', function() {
    const scrollPosition = window.scrollY;
    const background = document.getElementById('svgBackground');

    background.style.transform = `translateY(${scrollPosition * 0.5}px)`;
  });

  // Auto scroll to form
  const scrollToForm = () => {
    const form = document.getElementById('contactForm');
    form.scrollIntoView({behavior: 'smooth'});
  };

  const outlineCards = [
    {
      imgSrc: BoostEngagement,
      header: "Boost Engagement",
      paragraph: "Empower employees with daily wellbeing check-ins, encouraging consistent participation and improving overall engagement",
      color: "#cbe6f5",
    },
    {
      imgSrc: ReduceTurnover,
      header: "Reduce Turnover",
      paragraph: "Early identification of wellbeing concerns leads to proactive interventions, improving employee retention and reducing costly turnover",
      color: "#cbe6f5",
    },
    {
      imgSrc: EnhanceProductivity,
      header: "Enhance Productivity",
      paragraph: "Happier, healthier employees perform better, leading to increased productivity and workplace satisfaction",
      color: "#cbe6f5",
    },
    {
      imgSrc: CostEffective,
      header: "Cost Effective",
      paragraph: "Our solution offers multiple wellbeing features in one platform, delivering high value at a competitive price.",
      color: "#cbe6f5",
    },
    {
      imgSrc: ActionableInsights,
      header: "Actionable Insights",
      paragraph: "Gain access to real-time analytics and trends on employee wellbeing, enabling you to make informed decisions to foster a positive culture.",
      color: "#cbe6f5",
    }
  ];

  return (
    <>
    <div className={styles.sectionContainer}>
      <div className={styles.svgBackground} id="svgBackground">
          <img src={Heart1} alt="" class={styles.svgIcon} style={{top: "0%", left: "15%"}} />
          <img src={Heart2} alt="" class={styles.svgIcon} style={{top: "40%", left: "60%"}} />
          <img src={Pill1} alt="" class={styles.svgIcon} style={{top: "0%", left: "88%"}} />
          <img src={Heart2} alt="" class={styles.svgIcon} style={{top: "80%", left: "20%"}} />
          <img src={Pill2} alt="" class={styles.svgIcon} style={{top: "50%", left: "30%"}} />
          <img src={Pill1} alt="" class={styles.svgIcon} style={{top: "30%", left: "45%"}} />
          <img src={Pill2} alt="" class={styles.svgIcon} style={{top: "70%", left: "80%"}} />
      </div>

      <div className={styles.heroSection}>

        <div className={styles.heroText}>
          <h3 className={styles.heroTextHeader}>Introducing The New Best Employee Wellbeing Software</h3>
          <p className={styles.heroTextParagraph}>Ardorio is a SaaS company enhancing wellbeing management with
            proactive solutions, anonymous feedback, HR appointment setting,
            and global support. We offer cost-effective insights to boost employee
            satisfaction and organizational growth.</p>
          <Divider color="rgba(0, 0, 0, 0.4)" thickness="2px" style={{margin: '20px 0'}} />
          <div>
            <button className={styles.heroButton} onClick={scrollToForm}>Book a consultation</button>
          </div>
        </div>
        <div className={styles.heroImage}>
          <img src={HeroPhoto} alt="Hero" />
        </div>
      </div>

      <div className={styles.infoSection}>

        <div className={styles.infoCard}>
          <h2 className={styles.infoCardHeader}>Only 23%</h2>
          <p className={styles.infoCardParagraph}>of Australian and New Zealand exployees feel engaged at work, indiciating a
            significant challenge in employee wellbeing and productivity.
          </p>
        </div>

        <div className={styles.infoCard}>
          <h2 className={styles.infoCardHeader}>130% ROI</h2>
          <p className={styles.infoCardParagraph}>For every <strong>$1</strong> invested into mental health support and
          workplace improvement, there is an average return of <strong>$2.30</strong> in benefits.</p>
        </div>

        <div className={styles.infoCard}>
          <h2 className={styles.infoCardHeader}>$14.81 Billion</h2>
          <p className={styles.infoCardParagraph}>cost to the Australian economy due to workplace stress induced absenteeism.</p>
        </div>

      </div>

      <Divider color="rgba(0, 0, 0, 0.1)" thickness="1px" style={{margin: '40px 0'}} />

        <div className={styles.logoContainer}>
          <div className={styles.logos}>
            <img src={ForbesLogo} alt="" width={300} style={{borderRadius: 4}} />
            <img src={ForbesLogo} alt="" width={300} style={{borderRadius: 4}} />
            <img src={ForbesLogo} alt="" width={300} style={{borderRadius: 4}} />
          </div>
        </div>

      {/* VIDEO GOES HERE */}
      {/* <div className={styles.videoSection}>
        <img src={VideoPlaceholder} alt="video" />
      </div> */}

        <div className={styles.outlineSection}>
          {outlineCards.map((card, index) => (
            <div key={index} className={styles.outlineCard} style={{backgroundColor: card.color}}>
              <img src={card.imgSrc} alt="" width={200} height={130} style={{borderRadius: 4}}/>
              <div className={styles.outlineCardText}>
                <h2 className={styles.outlineCardHeader}>{card.header}</h2>
                <p className={styles.outlineCardParagraph}>{card.paragraph}</p>
              </div>
            </div>
          ))}
        </div>
    </div>

    <Divider color="rgba(96, 209, 157, 0.4)" thickness="2px" />

    <div id="contactForm" className={styles.formSection}>
      <div className={styles.formSectionContent}>
        <h2>Book a Consultation</h2>
        <p>Please fill out the form so a member from our team can contact you.</p>

      <form className={styles.consultationForm}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="companySize">Company Size:</label>
          <select id="companySize" name="companySize" required>
            <option value="">Select</option>
            <option value="1-10">1-10</option>
            <option value="11-50">11-50</option>
            <option value="51-200">51-200</option>
            <option value="201-500">201-500</option>
            <option value="501-1000">501-1000</option>
            <option value="1001+">1001+</option>
          </select>
        </div>
        <button type="submit" className={styles.submitButton}>Submit</button>
      </form>
      </div>
    </div>

    <Divider color="rgba(96, 209, 157, 0.4)" thickness="2px" />

    </>
  );
};

export default Home;
