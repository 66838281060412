import React from 'react';
import ArdorioLogo from '../../assets/ardorio.png';
import styles from './header.module.css';

const Header = () => {

  const scrollToForm = () => {
    const form = document.getElementById('contactForm');
    form.scrollIntoView({behavior: 'smooth'});
  }

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <img src={ArdorioLogo} alt="Logo" width={250}/>
      </div>
      <div>
        <button className={styles.contactButton} onClick={scrollToForm}>Book a Demo</button>
      </div>
    </header>
  );
};

export default Header;
