import './App.css';
import Home from './components/pages/Home/home';
import Footer from './components/elements/Footer/footer';
import Header from './components/elements/Header/header';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
