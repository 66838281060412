import React from 'react';
import PropTypes from 'prop-types';

const Divider = ({ color, thickness, style }) => {
  const dividerStyle = {
    backgroundColor: color,
    height: thickness,
    ...style,
  };

  return <div className="divider" style={dividerStyle}></div>;
};

Divider.propTypes = {
  color: PropTypes.string,
  thickness: PropTypes.string,
  style: PropTypes.object,
};

Divider.defaultProps = {
  color: '#000',
  thickness: '1px',
  style: {},
};

export default Divider;
